<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Unit</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Description</label>
                    <v-text-field
                        v-model="unityRequest.description" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Symbol</label>
                    <v-text-field
                        v-model="unityRequest.symbol" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Field Type</label>
                    <v-combobox
                        v-model="fieldTypeSelected"
                        :items="listFieldType" 
                        :rules=[validations.required]
                        item-text="description"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
            </v-row>

            <ActionButtons
                :request="unityRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

            unityRequest: {
                id: 0,
                description: "",
                symbol: "",
            },

            fieldTypeSelected: null,
            listFieldType: [
                { id: 1, description: 'Decimal'},
                { id: 2, description: 'Integer'}
            ],

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("unityModule/GetById", this.id);

                    if (response.success) {
                        this.unityRequest = response.result;
                        this.fieldTypeSelected = {
                            id: this.unityRequest.fieldType,
                            description: this.unityRequest.fieldTypeDescription
                        }
                    }
                }
            },

            async cancel() {
                this.$router.push({ path: "/common/unityList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.unityRequest.id = this.id;

                    if (this.fieldTypeSelected != null && this.fieldTypeSelected != undefined && this.fieldTypeSelected.id != 0) {
                        this.unityRequest.fieldType = this.fieldTypeSelected.id;
                    }

                    const result = await this.$store.dispatch("unityModule/CreateUpdate", this.unityRequest);

                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                        
                }
            }
        },

        created() {
            this.showLoading();

            this.getRegister();

            this.hideLoading();
        }
    })
</script>